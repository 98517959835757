<template>
<transition name="modal">
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container new-rec-container wide-modal" @click.stop="">

        <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('recordings.modalNewUploadRecording')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
            </v-btn>
        </div>

      <div class="modal-content">

      <div id="new-rec--stage-header">

        <div class="stage-bar" v-bind:class="{ stagebarcomplete : readyToSubmit }"></div>

        <div v-for="stage in stages" :key="stage.index" class="stage-div">

              <div
              class='material-icons stage-icon'
              v-bind:class="{ stagecomplete: stage.status }"
              >
              {{ stage.status ? "check_circle_outline" : "panorama_fish_eye" }}
              </div>
              <div class="stage-name" v-bind:class="{ stagecomplete: stage.status }">
                <div class="label" v-bind:class="[stage.name == currentStage ? 'currentstage' : '' ]">{{stage.label}}</div>
              </div>
        </div>
      </div>
  <transition name="slide-in" mode="out-in">
      <div id="upload-stage" v-show="currentStage == 'File'">
      <h3 class="modal-subheading">{{$t('recordings.modalNewChooseFile')}}</h3>
      <p>{{uploadRemaining | prettyBytes}} {{$t('recordings.modalNewUploadRemaining')}}</p>
      <div id="upload-button" v-show="!fileLoaded">
        <input type="file" name="file" id="file-input" class="custom-file-input">
        <label for="file-input">
            <div class="file-input-label">
              <i class="material-icons icon file-upload-icon">save_alt</i>
              <div class="file-input-label-text">
                <h4>{{$t('recordings.modalNewFileUpload')}}</h4>
                <div class="file-input-label-subtext">
                  {{$t('recordings.modalNewFileUploadTxt')}}
              </div>
              </div>
            </div>
        </label>
      </div>

<div id="upload-content">
    <div id="file-info" v-show="fileLoaded" v-bind:class="[error ? 'file-error' : 'file-valid']">

    <div id="file-details">

        <div class="media-icon">
          <i class="material-icons file-type-icon">{{mediaIcon}}</i>
        </div>

        <div class="file-text">
          <h5 class="file-name">{{fileName | text-shorten}}</h5>
          <div class="file-stat">{{$t('recordings.modalNewRecLength')}}: {{duration | timeFormat }}</div>
          <div class="file-stat">{{$t('recordings.modalNewFileSize')}}: {{fileSize | prettyBytes }}</div>
        </div>

        <div class="remove-upload">
                <v-btn icon class="icon-box" @click="removeFile">
                <i class="material-icons icon">clear</i>
                </v-btn>
        </div>

      </div>
    </div>

    <div class="fileupload" v-show="!fileLoaded">
      <audio id="audio"></audio>
    </div>

</div>

      <div class="upload-warning">
      <div >{{errorText}}</div>
      </div>

      <v-text-field
        placeholder="Enter name of recording or subject"
        append-icon="person"
        label="Name*"
        v-model="title"
        outlined
        counter="45"
        ></v-text-field>
  </div>
  </transition>

<transition name="slide-in" mode="out-in">
      <div id="info-stage" v-show="currentStage == 'Details'">

        <h3 class="modal-subheading">{{$t('recordings.modalNewEditOptions')}}</h3>

        <div class="modal-select" :class="{'unavailable' : !canEnhance}">
        <div class="modal-select-top" @click="enhanceToggle()" v-ripples :class="{'selected' : audioEnhanceSwitch}">
          <div class="modal-select-top-title">
          <i aria-hidden="true" class="v-icon material-icons theme--light">hearing</i>
          <p> {{premiumUser ? "Volume Correction" : "Volume Correction (premium account only)"}} </p>
                  <div class="flag-help" @click.stop="">
            <div class="material-icons"
                v-tooltip="{
                    content: audioEnhanceToolTip,
                    autoHide: true,
                    classes: 'app-tooltip',
                    trigger: 'hover active click',}"
                    >help_outline</div>
          </div>

        </div>

          <div class="transcribe-select-switch" @click.stop="">
              <v-switch
              v-model="audioEnhanceSwitch"
              color="#2196f3"
              :disabled="leftoverQuota <= 0 || !canEnhance"
              ></v-switch>
            </div>

        </div>
      </div>
      <div class="modal-select" >
        <div class="modal-select-top" @click="transcribeSwitch = !transcribeSwitch" v-ripples :class="{'selected' : transcribeSwitch}">
          <div class="modal-select-top-title">
          <i aria-hidden="true" class="v-icon material-icons theme--light">record_voice_over</i>
          <p>{{$t('recordings.modalNewTranscribeRecording')}} ({{ duration |  prettyDuration }}) </p>
          </div>

          <div class="transcribe-select-switch" @click.stop="">
            <v-switch
            v-model="transcribeSwitch"
            color="#2196f3"
            :disabled="leftoverQuota <= 0"
            ></v-switch></div>
        </div>

        <transcribe-status
        :leftoverQuota="leftoverQuota"
        :remainingQuota="remainingQuota"
        :duration="duration"
        ></transcribe-status>

      </div>

    </div>
</transition>
<transition name="slide-in" mode="out-in">
  <div v-show="currentStage == 'Transcription'">

      <div
      v-bind:class="[transcribeSwitch ? '' : 'transcription-no']">
        <h3 class="modal-subheading">{{$t('recordings.modalNewHelpTranscript')}}</h3>
      </div>

<div id="transcribing-inputs">

        <v-select class="form-field"
          label="Language or dialect"
          v-model="currentLanguage"
          :items="languageOptions"
          append-icon="language"
          menu-props="auto"
          outlined
          v-on:change="languageSelect"
        ></v-select>

    <v-select class="form-field"
        label="Number of speakers*"
        :items="speakerItems"
        append-icon="people"
        outlined
        placeholder="How many voices will we hear in the recording"
        v-model="speakers"
        :disabled="!transcribeSwitch"
        ></v-select>
    </div>

    <vocab-input v-if="collection.CollectionID"
    :collection="collection"
    :disabled="!transcribeSwitch"
    :collectionID="collection.CollectionID"
    ></vocab-input>

    </div>
</transition>

    </div>

    <div class="button-row">

      <v-btn
      v-if="currentStage == 'File'"
      @click="nextStage('Details')"
      class="focus-button"
      color="blue"
      light
      :disabled="!fileLoaded || error || !validTitle "
      >{{$t('actions.next')}}
      <div class='material-icons'>chevron_right</div>
      </v-btn>

      <v-btn
      v-if="currentStage == 'Details'"
      @click="currentStage = 'File'"
      class="focus-button"
      color="grey"
      dark
      >
      <div class='material-icons'>chevron_left</div>
      {{$t('actions.back')}}
      </v-btn>

      <v-btn
      v-if="currentStage == 'Details' && transcribeSwitch"
      @click="nextStage('Transcription')"
      class="focus-button"
      color="blue"
      :disabled="(leftoverQuota - pendingQuota) < 0"
      light
      >{{$t('actions.next')}}
      <div class='material-icons'>chevron_right</div>
      </v-btn>

      <v-btn
      v-if="(!transcribeSwitch && currentStage == 'Details')"
      class="focus-button"
      color="success"
      light
      :disabled="!readyToSubmit"
      @click="createRecording"
      >{{$t('actions.done')}}
      <div class='material-icons upload-icon'>check</div>
      </v-btn>

      <v-btn
      v-if="currentStage == 'Transcription'"
      @click="currentStage = 'Details'"
      class="focus-button"
      color="grey"
      >
      <div class='material-icons'>chevron_left</div>
      {{$t('actions.back')}}
      </v-btn>

      <v-btn
      v-if="(currentStage == 'Transcription')"
      class="focus-button"
      color="success"
      light
      :disabled="!readyToSubmit"
      @click="createRecording"
      >{{$t('actions.done')}}
      <div class='material-icons upload-icon'>check</div>
      </v-btn>

    </div>
    </div>
    </div>


      </div>
</transition>
</template>

<script>
import {mapGetters} from 'vuex'
import uppyAll from './../../uppy'
import requests from './../../requests'
import analytics from './../../analytics'
import VocabInput from './../VocabInput'
import TranscribeStatus from './TranscribeStatus'
import EventBus from './../../eventBus'

export default {
props: {
  collectionID: String,
  recordingCount: Number,
  collection: Object,
},
data () {
    return {
        currentStage: "File",
        stages: [
          {name: "File", label: "1. File", status: false},
          {name: "Details", label: "2. Options", status: false},
          {name: "Transcription", label: "3. Transcription", status: false}
        ],
        audioStream: null,
        duration: 0,
        uploading: false,
        title: "",
        date: new Date().toISOString().substring(0,10),
        dateMenu: false,
        transcribeSwitch: true,
        audioEnhanceSwitch: false,
        enhanceAvailable: true,
        speakers: null,
        speakerItems: [1,2,3,4,5,6,7,8,9,10],
        showVocabHelp: false,
        errorFileType: "",
        errorText: " ",
        error: false,
        file: {},
        currentFile: {
          data: {
            id: "",
            name: "",
            size: "",
            type: ""
          }
        },
        currentLanguage: "en-US",
        languageOptions: [
          {text: "English (US)", value: "en-US"},
          {text: "English (British)", value: "en-GB"},
          {text: "English (Australian)", value: "en-AU"},
          {text: "English (Indian)", value: "en-IN"},
          {text: "Arabic", value: "ar-SA"},
          {text: "French", value: "fr-FR"},
          {text: "French (Canadian)", value: "fr-CA"},
          {text: "German", value: "de-DE"},
          {text: "Hindi", value: "hi-IN"},
          {text: "Italian", value: "it-IT"},
          {text: "Portugese", value: "pt-BR"},
          {text: "Russian", value: "ru-RU"},
          {text: "Spanish", value: "es-ES"},
          {text: "Spanish (US)", value: "es-US"}
        ],
      audioEnhanceToolTip: `
            <div class="flag-tooltip">
                <div>
                 ${this.$t('recordings.modalNewAudioEnhance')}
                </div>
                <br>
                <div>${this.$t('recordings.modalNewAudioEnhanceTime')}</div>
            </div>
            `
    }
},
components: {
  VocabInput,
  TranscribeStatus
},
methods : {
  nextStage: function (stage) {

    this.stages.map( stage => {

        if (this.currentStage == stage.name) {
          stage.status = true
        }
        return stage
    })

    this.currentStage = stage

  },
  uploadFile: function () {
    uppyAll.uppyOne.upload()
  },
  closeModal: function () {
    this.removeFile()
    this.$emit('close')
  },
  removeFile:  function () {

    if (this.fileLoaded) {

      this.clearWarning()
      this.clearFile()
      uppyAll.uppyOne.removeFile(this.currentFile.id)

      this.currentFile = {
          data: {
            id: "",
            name: "",
            size: "",
            type: ""
          }
        }}
  },
  createRecording: async function () {

    if (this.title.length > 45 || (this.leftoverUpload - this.pendingQuota) < 0) {
      return
    }

    this.uploading = true

    let transcribe = false
    if (this.transcribeSwitch) {
      transcribe = true
    }

    let d = Date.parse(this.date)
    let newDate = new Date(d).toISOString()

    // need to add check that below values exist/are vaid
    const recording = {
      Name: this.title,
      Date: newDate,
      Speakers: this.speakers,
      UploadStatus : "IN_PROGRESS",
      Transcribe: transcribe,
      VocalEnhance: this.audioEnhanceSwitch,
      MediaType: this.currentFile.type,
      MediaDuration: this.duration,
      MediaSize: this.fileSize,
      Language: this.currentLanguage
    }

    const recDetails = await requests.createRecording(this.collectionID, recording)
        if (recDetails.status == 200 && this.userID) {
          // s3MultiPart doesnt support custom metadata so we have to change the filename on upload
          uppyAll.uppyOne.setFileMeta(this.currentFile.id, {
            "UserID": this.userID,
            "CollectionID": recDetails.data.data.CollectionID,
            "RecordingID": recDetails.data.data.ItemID,
            "Duration": this.duration,
            "Transcribe": transcribe,
            "Title": recording.Name,
          })

          uppyAll.uppyOne.upload()

          this.$store.dispatch('SET_RECORDINGS', this.collectionID)

          let durationUpdate = 0
          if (transcribe) {
            durationUpdate = this.duration
          }

          this.$store.dispatch('INCREMENT_PENDING', {
            duration: durationUpdate,
            size: this.fileSize,
          })

          this.$emit('close')

          const urlObj = {
            collectionid: recDetails.data.data.CollectionID,
            itemid: recDetails.data.data.ItemID,
            url: this.audioEl.src
          }

          this.$store.dispatch("ADD_LOCAL_REC_URL", urlObj)

          const recID = recDetails.data.data.ItemID
          const collID = recDetails.data.data.CollectionID

          this.$router.push({ name: 'recording', params: { collID, recID } })

        } else if (recDetails.status == 401) {
          this.uploading = false
          EventBus.$emit('SHOW_MESSAGE', 'Upload restricted', 'red')

        } else if (!recDetails.status == 200) {
          this.uploading = false
          EventBus.$emit('SHOW_MESSAGE', 'Failed to upload', 'red')

        }
      },
      checkUploadedFile() {

          this.loadingDetails = true
          var self = this

          this.audioEl.onloadedmetadata = function () {
            self.setDuration()
            self.checkLimits()
          }

      },
      languageSelect(language) {
        this.currentLanguage = language
      },
      setDuration () {

            if (!isFinite(this.audioEl.duration)) {
              this.errorText = this.$t('recordings.modalNewDurationError')
              this.error = true
            }

            this.duration = this.audioEl.duration
            this.loadingDetails = false

      },
      checkLimits () {
        const file = this.file
        let limit = 5
        let enhanceLimit = 5

        // limit is the amount of hours each file type can be uploaded
        switch (true) {
          case file.type.includes("mp4"):
            limit = 4
            enhanceLimit = 2
            break
          case file.type.includes("video"):
            limit = 2
            enhanceLimit = 2
            break
          case file.type.includes("mp3") || file.type.includes("m4a") :
            limit = 5
            break
          case file.type.includes("audio"):
            limit = 3
            break
        }

        if (this.duration > limit * 60 * 60) {
              this.errorText = `Maximum duration for ${file.type} files is ${limit} hours`
              this.error = true
        }

        if (this.duration > enhanceLimit * 60 * 60) {
            this.enhanceAvailable = false
        }

        },
      checkDuration () {

          const durationLimit = 300 * 60 // 120 mins = 2 hours

          if (this.duration > durationLimit) {
              this.errorText = "Maximum file duration is 2 hours"
              this.error = true
              return false
          }

          return true

        },

        clearWarning () {
          this.errorText = " "
        },
        clearFile () {
          this.audioEl.src = ""
          this.duration = 0
          this.error = false
        },
        checkSize () {

          if (!this.canUpload) {
            this.error = true
            this.errorText = this.$t('recordings.modalNewSizeError')
          }

        },
        makeTitleName (filename) {

          if (!filename) {
            return filename
          }

          let s = filename.split(".")

          s[0].replace("_", " ")

          return s[0]

        },
        enhanceToggle() {

          if (!this.canEnhance) {
            this.audioEnhanceSwitch = false
            return
          }

          this.audioEnhanceSwitch = !this.audioEnhanceSwitch

        }
},
computed: {
    ...mapGetters({
      cognitoInfo: 'COGNITO_INFO',
      windowWidth: 'WIDTH',
      userDetails: 'USER_DETAILS',
      currentTranscribeQuota: 'CURRENT_DURATION_UPLOADING',
      userPlan: 'USER_PLAN',
      premiumUser: "PREMIUM_USER"
    }),
    userID () {
      return this.cognitoInfo.sub
    },
    uppyID () {
      return this.collectionID + this.recordingCount
    },
    uppyData () {
      return uppyAll.uppyOne.getState()
    },
    fileName () {
      if (this.fileLoaded) {
        return this.currentFile.data.name
      } else {
        return ''
      }
    },
    fileSize () {
      if (this.fileLoaded) {
        return this.currentFile.data.size
      } else {
        return 0
      }
    },
    fileLoaded: function () {
      if (this.currentFile.data.id == "") {
          return false
      } else {
        return true
      }
    },
    validTitle: function () {
      return this.title.length > 0 && this.title.length <= 45 ? true : false
    },
    readyToSubmit: function () {
      if (this.fileLoaded && this.title && this.validTitle && !this.uploading && !this.transcribeSwitch && !this.error && this.canUpload) {
        return true
      } else if (this.fileLoaded && this.title && this.validTitle && !this.uploading && this.transcribeSwitch && this.speakers && !this.error && this.canUpload) {
        return true
      } else {
        return false
      }
    },
    uploadRemaining () {
        return this.userDetails.UploadQuota - this.userDetails.UsedUploadQuota
    },
    leftoverUpload () {
        return this.uploadRemaining - this.fileSize
    },
    canUpload () {
        return this.leftoverUpload > 0
    },
    pendingQuota () {
        return this.userDetails.PendingTranscribeQuota
    },
    totalQuota () {
        return this.userDetails.TranscribeQuota
    },
    remainingQuota () {
      return this.totalQuota - (this.pendingQuota + this.currentTranscribeQuota)
    },
    leftoverQuota () {

      if (this.duration > 0) {
        let mins = Math.ceil(this.duration / 60)
        return this.remainingQuota - mins
      } else {
        return null
      }
    },
    extraCost () {
      return Math.abs(Math.round(this.leftoverQuota * this.additionalTranscriptionCost * 10) / 10)
    },
    additionalCost () {
      return this.leftoverQuota < 0 && this.transcribeSwitch
    },
    switchLabel () {
      return this.transcribeSwitch ? "Yes" : "No"
    },
    audioSwitchLabel () {
      return this.audioEnhanceSwitch ? "Yes" : "No"

    },
    mediaIcon () {

      if (this.error) {
        return "close"
      }

      if (this.currentFile.type == 'audio/mp3') {
        return "mic"
      }

      return "videocam"

    },
    canEnhance() {
      return this.premiumUser && this.enhanceAvailable
    }
  },
  // beforeDestroy () {

  // },
  mounted () {
    this.audioEl = document.querySelector("#audio")
    var self =  this

    this.audioEl.onerror = function() {
      if (self.fileLoaded && self.audioEl.error.code == 4) {

        analytics.gaError('upload_error', self.errorFileType)
        self.errorText = this.$t('recordings.modalNewTypeError')
        self.error = true
      }
    }

    uppyAll.uppyOne.on('file-added', (file) => {
      this.currentFile = file
      this.title = this.makeTitleName(this.currentFile.data.name)

    })

    uppyAll.uppyOne.on('info-visible', () => {
        const info = uppyAll.uppyOne.getState().info
        this.errorText = info.message
    })

    let fileInput = document.querySelector("#file-input")

    fileInput.addEventListener('change', function(e) {

      //clear any warning text that may be still there
      self.clearWarning()

      //set the error file type, in case of any upload errors
      self.errorFileType = e.currentTarget.files[0].type

      // load the users selected file into the audio element
      self.audioEl.src = URL.createObjectURL(e.currentTarget.files[0])

      // set the duration attribute
      self.file = e.currentTarget.files[0]

      self.checkUploadedFile()

      // add the files into Uppy uploader
      // if any error occurs, remove the file from input/audio sources
      const files = Array.from(e.target.files)

      files.forEach((file) => {

          try {
            uppyAll.uppyOne.addFile({
              source: 'file input',
              name: file.name,
              type: file.type,
              data: file
            })

          self.checkSize()

          } catch (err) {

            analytics.gaError('upload_restricted', self.errorFileType)
            if (err) {self.clearFile()}

          }
        })
      })
  },
  watch: {
    speakers: function () {

      if (this.speakers) {
        // this is really badly hard coded.
        this.stages[2].status = true;
      } else {
        this.stages[2].status = false;
      }

    },
    transcribeSwitch: function () {
      if ((!this.transcribeSwitch && this.title) || (this.transcribeSwitch && this.title) ) {
        this.stages[1].status = true
      } else {
        this.stages[1].status = false
      }
    },
    title: function () {

      if ((!this.transcribeSwitch && this.title) || (this.transcribeSwitch && this.title) ) {
        this.stages[1].status = true
      }
      else {
        this.stages[1].status = false
      }
    },
    fileLoaded: function () {
      if (this.fileLoaded) {
        this.stages[0].status = true
      } else {
        this.stages[1].status = false
      }
    },
    duration: function () {
      this.checkDuration()
    },
  }
}

</script>

<style scoped lang="scss">

@import "@/styles/abstracts/_variables";
@import "@/styles/abstracts/_mixins";
@import "@/styles/components/_tooltips";

.modal-content {
  overflow-x: hidden;
  text-align: center;

}

#add-rec-buttons {
  justify-content: flex-end;
  background-color: white;
  margin: 0 auto;
}

.new-rec-container .modal-controls {
  padding-top: 1em;
}

.bottom-border div {
  color: rgba(0,0,0,.34);
  font-size: 12px;
  text-align: right;
  margin: 0 10px;
  font-family: Helvetica, Arial, sans-serif
}

.modal-select {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 2em auto;
  cursor: pointer;
}

.modal-select p {
  color: rgba(0,0,0,.82);
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  text-align: left;
  margin: 0 10px;
  font-weight: 700;
}

.modal-select-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  height: 56px;
  border: 1px solid rgba(0,0,0,.54);
  border-radius: 4px;
  padding: 3em 2em;
}

.modal-select-top.selected {
    border: 1px solid $accent-color;
    background-color: $accent-color-faint;
}

.modal-select-top.selected .v-icon {
  color: $accent-color;
}

.modal-select-top-title {
  display: flex;
  align-items: center;
}


.no-touch .transcribe-select .link-highlight:hover * {
  color: $accent-color;
  cursor: pointer;
}


.transcription-no *{
  color: rgba(0,0,0,.2);
}

.bottom-border i {
    color: rgba(0,0,0,.34);
}

.bottom-border {
  flex-grow: 2;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.rec-info {
  width: 80%
}


#upload-button {
  // margin: 10px 0px 20px 0px;
  margin: 0 auto;
  height: 160px;
  /* border: 2px dashed rgba(160, 160, 160, 0.251);
  border-radius: 10px; */
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .56);
  display: flex;
  align-items: center;
}

.custom-file-input {
  visibility: hidden;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  color: white;
}

.custom-file-input + label {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.custom-file-input:focus + label,
.no-touch .custom-file-input + label:hover{
    background-color:#34B6FC10;
    background-image: linear-gradient(23deg, transparent 10%, rgba(210, 210, 210, 0.314));
}

.custom-file-input + label * {
	pointer-events: none;
}

.file-input-label {
  display: flex;
  align-items: center;
}

.no-touch .custom-file-input + label:hover i {
  color: #2196f3;
}

.file-input-label i {
  font-size: 48px;
  margin: 24px 24px 24px 32px;
  color: rgba(0,0,0,.54)
}

.file-input-label-text {
  margin: 24px auto;
  text-align: left;
}

.file-input-label-subtext {
    color: rgba(0,0,0,.54);
    font-size: 12px;
}


.form-field {
  margin-bottom: 32px;
}

.form-subheading-left{
  margin-left: 32px;
  bottom: -12px;
  position: relative;
  color: rgba(0,0,0,.54);
  text-align: left;
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
}
/*
#upload-content {
  margin: 10px 0px 20px 24px;
} */


#file-info {
  border-radius: 10px;
}

.file-error {
  border: 1px solid rgba(255, 0, 0, .33);
  background-color:rgba(189, 189, 189, 0.125);

}

.file-valid {
  border: 1px solid #34B6FC20;
  background-color:#34B6FC20;
  background-image: linear-gradient(23deg, transparent 20%, #2AFD8B20);
}

.upload-warning {
  min-height: 3em;
  overflow: hidden;
}

.upload-warning, .upload-warning i {
   color:rgba(255, 0, 0, .55);
   font-weight: bold;
}

.file-type-icon {
  border: $text-color 2px solid;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  font-size: 40px;
  // padding: 12px 0 0 0;
  color:#2c3e50;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-text {
  flex-grow: 2;
  margin: 0 24px 0 0;
  max-width: 55%;
}

.file-name {
  margin: 12px 0;
  font-size: 16px;
  text-align: left;
  width: 100%;
  overflow: hidden;
}

.file-stat {
  font-size: 14px;
  text-align: left;
  color: rgba(0, 0, 0, .56)

}

#file-details {
  padding: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .56);
}

#transcribing-inputs {
  margin: 20px 0 20px 0 ;
}

#app .v-btn__content {
  color: white;
}

.show-help {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.show-help * {
  margin: 0 2px 16px 2px;
  color: rgba(0, 0, 0, .33);
}

.modal-subheading {
  margin: 0.5em auto;
  color: $title-color;
  font-size: 1.3em;
}

.stagecomplete {
  color: $accent-color;
}

// @media (min-width: $breakpoint-medium) {

//   // #app .modal-content {
//   //     min-height: 550px;
//   // }

// }

@media (min-width: 1px) and (max-width: $breakpoint-medium) {

  .modal-inputs {
    padding: 1em 2em;
  }

  #app #upload-button {
    margin: 0;
    height: 100px;
  }



  // #app .modal-inputs {
  //   padding: 0 2em;
  //   scrollbar-width: none;
  // }

  .transcribe-select .transcribe-quota {
    flex-flow: row wrap;
  }

//   #app .new-rec-container {
//     height: 100vh;
//     // padding: 1em 0;
//     overflow-y: scroll;
//     scrollbar-width: none;
//     margin: 0 auto;
//     border-radius: 0;
// }



}

#upload-stage {
  margin: 2em auto;
  padding: 1.5em 0;
}

#new-rec--stage-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1em auto 2em auto;
  position: relative;
}

#new-rec--stage-header * {
  transition: all 0.8s
}

.stage-icon {
  margin: 0 0.4em;
  background-color: white;
  z-index: 2;
  color: rgb(220, 220, 220);
}

#new-rec--stage-header .stage-div .currentstage {
  opacity: 1;
  border-bottom: 2px solid #0BAAFF40;
}

.stage-bar {
  width: 65%;
  position: absolute;
  top: 0.8em;
  border:1px solid rgb(220, 220, 220);
}

.stagebarcomplete {
  border: 1px solid #0BAAFF;
}

.stage-div {
  display: flex;
  width: 130px;
  align-items: center;
  flex-flow: column;
  font-weight: 700;
  color: rgb(100, 100, 100);
}

.stage-div .label {
  opacity: 0.5;
}


.upload-icon {
 margin-left: 1em;
}

.modal-warning {
  text-align: left;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 3em;
  font-size: 0.9em;
}

</style>


<style lang="scss">

#app .uppy-DragDrop-inner {
  padding: 40px 20px;
  margin-bottom: 8px;
}

#app .uppy-DragDrop-arrow {
  width: 30px;
  height: 30px;
}

  // #app .new-rec-container {
  //   max-width: 850px;
  // }



</style>

