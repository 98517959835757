import { render, staticRenderFns } from "./NewRecMD.vue?vue&type=template&id=72de19bc&scoped=true"
import script from "./NewRecMD.vue?vue&type=script&lang=js"
export * from "./NewRecMD.vue?vue&type=script&lang=js"
import style0 from "./NewRecMD.vue?vue&type=style&index=0&id=72de19bc&prod&scoped=true&lang=scss"
import style1 from "./NewRecMD.vue?vue&type=style&index=1&id=72de19bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72de19bc",
  null
  
)

export default component.exports