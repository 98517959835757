<template>
    <div id="vocab-inputs">

    <div class="vocab-textfield">
    <v-text-field
        v-model="term"
        append-icon="post_add"
        :disabled="disabled"
        outlined
        :placeholder="$t('vocab.fieldPlaceholder')"
        :label="$t('vocab.fieldLabel')"
        clearable
        @keyup.enter="addVocabTerm"
    ></v-text-field>

    <v-btn  class="btn-add" v-tooltip="{ content: $t('vocab.addTooltip'), classes: 'app-tooltip'}" height="56px"
    :disabled="term == '' || disabled"
    color="#0BAAFF"
    light
    @click="addVocabTerm()"
    ><i class="material-icons"
    >add</i></v-btn>
    </div>

    <div class="upload-warning">{{errorVocab}}</div>

    <div class="vocab-box">

    <v-chip close color="primary"
        v-for="(term, index) in vocabulary" :key="index"
        class="vocab-chip"
        @click:close="deleteTerm(index)"
    >
        {{term}}
    </v-chip>

    </div>

    </div>
</template>

<script>
import requests from './../requests'

export default {
    props: ['collection', 'collectionID' ,'disabled'],
    data () {
        return {
            term: '',
            errorVocab: '',
            vocabulary: []
        }
    },
    computed : {
        // vocabulary () {
        //     return this.$store.getters.GET_VOCABULARY
        // },
    },
    methods: {
    addVocabTerm: async function () {

      this.errorVocab = " "

      // remove whitespace on either end
      let term = this.term.trim()
      //Check to determine if text field has any special characters besides hyphen and period
      //Give error and return if it does
      let specialChar = /[!@#$%^&*()_+=[\]{};':"\\|,<>/?]/
      if (specialChar.test(term) || term == "" ) {
          this.errorVocab=this.$t('vocab.termError')
          return
      }

      // Clean up the term to make it either a lowercase hyphenated term (e.g. Noun), or
      // capitalise and separate with periods if acronym
      term = this.cleanTerm(term)


      if (this.vocabulary && this.vocabulary.includes(term)) {
        return
      }

    //   const payload = {
    //       collID: this.collection.CollectionID,
    //       detailsID: this.collection.ItemID,
    //       term: term,
    //   }

        const response = await requests.createVocabulary(this.collection.CollectionID, this.collection.ItemID, term)
        if (response.status == 200) {
            this.vocabulary = response.data.data
        } else {
            this.errorVocab = this.$t('vocab.addError')
        }

      this.term = ""
    },
    cleanTerm (term) {

        // replace all spaces with dashes
        let cleanedTerm = term.replace(/ /g, "-")

        // Quick check to see if it is 'probably' an acronym
        // By counting how many periods it has vs. how many letters (haha...)
        // If it has the same number or one less period. e.g. 'W.T.F.' or 'W.T.F'
        const periods = cleanedTerm.replace(/[^.]/g, "").length
        const range = cleanedTerm.length - periods * 2


        // Basic check to force the correct format if the term is an acronym
        // Doesn't do anything if format is weird - e.g. F..PR.Y
        if (range >= 0 && range <= 1 && periods > 0) {
            cleanedTerm = cleanedTerm.replace(/\./g, "").split("").join(".").toUpperCase()
            cleanedTerm = cleanedTerm + '.'
            return cleanedTerm
        } else {
            return cleanedTerm.toLowerCase()
        }

        },
        deleteTerm: async function(index) {

            const response = await requests.deleteVocabulary(this.collection.CollectionID, this.collection.ItemID, index)
            if (response.status == 200) {
                this.vocabulary = response.data.data
            } else {
                this.errorVocab = this.$t('vocab.deleteError')
            }

        },
        getVocab: async function() {

            const response = await requests.getVocabulary(this.collectionID)
            if (response.status == 200) {
                this.vocabulary = response.data.data
            } else {
                this.errorVocab = this.$t('vocab.fetchError')
            }

        }
    },
    watch: {
        'collection': async function() {
            this.getVocab()
        }
    },
    mounted: async function() {
        if (this.collection) {
            this.getVocab()
        }

    }
}
</script>

<style scoped>
.upload-warning, .upload-warning i {
   color:rgba(255, 0, 0, .55);
   font-weight: bold;
}

.vocab-textfield {
    display: flex;
}

.btn-add {
    top: 0;
    left: 12px;
    border: 1px solid rgba(0, 0, 0, .38);
    border-radius: 4px;
    height: 58px;
    margin: 0 1em 0 0;
    width: 56px;
    color: white;
}

.vocab-chip {
    margin: 0 0.3em;
}

</style>